import React from 'react'
import './footer.css'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div>
        <footer className="original-footer">
            <div className="page-width">
              <ul class="policy_menus">
                <li><Link to="/policy/privacy">Privacy Policy</Link></li>
                <li><Link to="/policy/terms">Terms of Service</Link></li>
              </ul>
             <p>© 2024, Veysel's Private Lessons</p>
            </div>
        </footer>
    </div>
  )
}

export default Footer