import React from 'react'
import mainImage from '../../images/about/about.svg'
import './about.css'
import { Element } from 'react-scroll';

const About = () => {
  return (
    <Element name="coach">
    <div className="body04 template--22485124382998__body4">
        <div className="_contents">
            <div className="_content-flex">
                
                <div className="img_wrap">
                    <img src={mainImage} alt="Veysel private tennis lesson" />
                </div>
                
                <div className="_txtfeild-inner">
                <h2>About COACH</h2>
                <div><p>Hello,
I am Veysel, a dedicated tennis trainer with a unique background in project management from Turkey. My journey from managing complex projects to coaching tennis has equipped me with a distinctive set of skills that enhance my coaching approach.</p><p>In my training sessions, I emphasize meticulous attention to detail, combining technical precision with a deep understanding of the mental and philosophical aspects of tennis. My philosophy is to tailor each lesson to the individual needs of my students, ensuring a comprehensive and personalized development plan.</p><p>Whether you are a beginner looking to learn the basics or an experienced player aiming to refine your skills, my goal is to help you achieve your full potential on the court. Join me for a transformative tennis training experience that blends strategic insight with passionate coaching.</p><p>Best regards,<br />Veysel</p></div>
                    <ul className="socialmedia">
                        <li>
                            <a href="https://www.instagram.com/veyselace/" target="_blank" rel="noreferrer"><svg aria-hidden="true" focusable="false" className="icon icon-instagram" viewBox="0 0 20 20">
                            <path fill="currentColor" fillRule="evenodd" d="M13.23 3.492c-.84-.037-1.096-.046-3.23-.046-2.144 0-2.39.01-3.238.055-.776.027-1.195.164-1.487.273a2.43 2.43 0 0 0-.912.593 2.486 2.486 0 0 0-.602.922c-.11.282-.238.702-.274 1.486-.046.84-.046 1.095-.046 3.23 0 2.134.01 2.39.046 3.229.004.51.097 1.016.274 1.495.145.365.319.639.602.913.282.282.538.456.92.602.474.176.974.268 1.479.273.848.046 1.103.046 3.238.046 2.134 0 2.39-.01 3.23-.046.784-.036 1.203-.164 1.486-.273.374-.146.648-.329.921-.602.283-.283.447-.548.602-.922.177-.476.27-.979.274-1.486.037-.84.046-1.095.046-3.23 0-2.134-.01-2.39-.055-3.229-.027-.784-.164-1.204-.274-1.495a2.43 2.43 0 0 0-.593-.913 2.604 2.604 0 0 0-.92-.602c-.284-.11-.703-.237-1.488-.273ZM6.697 2.05c.857-.036 1.131-.045 3.302-.045 1.1-.014 2.202.001 3.302.045.664.014 1.321.14 1.943.374a3.968 3.968 0 0 1 1.414.922c.41.397.728.88.93 1.414.23.622.354 1.279.365 1.942C18 7.56 18 7.824 18 10.005c0 2.17-.01 2.444-.046 3.292-.036.858-.173 1.442-.374 1.943-.2.53-.474.976-.92 1.423a3.896 3.896 0 0 1-1.415.922c-.51.191-1.095.337-1.943.374-.857.036-1.122.045-3.302.045-2.171 0-2.445-.009-3.302-.055-.849-.027-1.432-.164-1.943-.364a4.152 4.152 0 0 1-1.414-.922 4.128 4.128 0 0 1-.93-1.423c-.183-.51-.329-1.085-.365-1.943C2.009 12.45 2 12.167 2 10.004c0-2.161 0-2.435.055-3.302.027-.848.164-1.432.365-1.942a4.44 4.44 0 0 1 .92-1.414 4.18 4.18 0 0 1 1.415-.93c.51-.183 1.094-.33 1.943-.366Zm.427 4.806a4.105 4.105 0 1 1 5.805 5.805 4.105 4.105 0 0 1-5.805-5.805Zm1.882 5.371a2.668 2.668 0 1 0 2.042-4.93 2.668 2.668 0 0 0-2.042 4.93Zm5.922-5.942a.958.958 0 1 1-1.355-1.355.958.958 0 0 1 1.355 1.355Z" clipRule="evenodd"></path>
                            </svg>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
           
        </div>
        
        
    </div>
    </Element>
  )
}

export default About