import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import Header from './components/Header';
import HomePage from './components/HomePage';
import { PrivacyPolicy } from './components/Policy/PrivacyPolicy';
import Footer from "./components/footer/Footer";
import { Terms } from "./components/Policy/Terms";
import ScrollToTop from './components/ScrollToTop';
import ReactGA from "react-ga4";

ReactGA.initialize("G-MSJTDLMLPR");

function App() {
  return (
    <BrowserRouter>
     <ScrollToTop />
      <div className='App'>
        <Header />
        
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/policy/privacy" element={<PrivacyPolicy />} />
          <Route path="/policy/terms" element={<Terms />} />
        </Routes>
        <Footer/>
      </div>
    </BrowserRouter>
  );
}

export default App;
