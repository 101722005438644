import React from 'react'
import './stories.css';
import { GiTennisBall } from "react-icons/gi";
import { BsFillPersonFill } from "react-icons/bs";
import { IoTimeSharp } from "react-icons/io5";

export default function Stories() {
  return (
    <div className="stories">
        <div className="_contents">
            <h4 className='_subtitle'><span className="highlight">Personalized Coaching in Action</span></h4>
            <h2><span className="highlight">S</span>tories from Our Tennis Students</h2>
            <ul>
                <li className='_lists fadeIn'>
                    <div className="_inner">
                        <div className="_info">
                           <h3>Story 1</h3>
                            <ul className='_items'>
                                <li><BsFillPersonFill /><span>Emily</span></li>
                                <li><GiTennisBall /><span>Beginner</span></li>
                                <li><IoTimeSharp /><span>10:00-11:00 Tuesdays </span></li>
                            </ul>
                        </div>
                        <div className="_description">
                          <h4>Background:</h4>
                          <p>Emily had never played tennis before but was eager to learn. Her parents enrolled her in private tennis lessons to help her develop a new skill and stay active.</p>

                          <h4>Challenges:</h4>
                          <p>Emily initially struggled with basic techniques and coordination. She found it difficult to keep up with more experienced players in group lessons and felt discouraged.</p>

                          <h4>Solution:</h4>
                          <p>Through personalized, one-on-one coaching, Emily received tailored instructions and exercises that matched her pace. I focused on building her confidence, teaching her the fundamentals of tennis, and making the lessons fun and engaging.</p>
                        </div>
                    </div>
                </li>
                <li className='_lists fadeIn'>
                    <div className="_inner">
                    <div className="_description">
                          <h4>Background:</h4>
                          <p>James had been playing tennis recreationally for a few years and participated in local tournaments. However, he felt that his progress had plateaued, and he was no longer improving at the same rate.</p>

                          <h4>Challenges:</h4>
                          <p>James struggled with experienced techniques and strategies. He often lost matches due to inconsistent serves and poor game planning.</p>

                          <h4>Solution:</h4>
                          <p>With private lessons, James received detailed feedback on his technique and strategic advice tailored to his play style. I worked on refining his serves, improving his footwork, and developing match strategies to enhance his competitive edge.</p>
                        </div>
                        <div className="_info">
                           <h3>Story 2</h3>
                            <ul className='_items'>
                                <li><BsFillPersonFill /><span>James</span></li>
                                <li><GiTennisBall /><span>Intermediate</span></li>
                                <li><IoTimeSharp /><span>18:00-21:00 Fridays</span></li>
                            </ul>
                        </div>
                        
                    </div>
                </li>
                <li className='_lists fadeIn'>
                    <div className="_inner">
                        <div className="_info">
                           <h3>Story 3</h3>
                            <ul className='_items'>
                                <li><BsFillPersonFill /><span>John</span></li>
                                <li><GiTennisBall /><span>Former Player, Returning to the Sport</span></li>
                                <li><IoTimeSharp /><span>8:00-9:00 Tuesdays or Mondays </span></li>
                            </ul>
                        </div>
                        <div className="_description">
                          <h4>Background:</h4>
                          <p>John played tennis in his youth and competed in local tournaments, but life responsibilities and work commitments caused him to step away from the sport for many years. Now, at 58, John is looking to rediscover his passion for tennis and stay active.</p>

                          <h4>Challenges:</h4>
                          <p>John faced several challenges upon his return. His muscle memory and technique were rusty after years of inactivity. He needed to improve his fitness and endurance while avoiding injuries as he got back into shape.</p>

                          <h4>Solution:</h4>
                          <p>Through personalized coaching sessions, John received tailored exercises to rebuild muscle memory and refine his technique. I provided fitness and conditioning routines to safely improve his overall fitness, along with guidance on proper stretching and warm-up techniques to prevent injuries.</p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
  )
}
