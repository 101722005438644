import React from 'react'
import './whatcanido.css';

export const Whatcanido = () => {
  return (
    <div className='whatcanido'>
        <div className='_contents'>
            <div className='grid-item'>
                <h2>What Can<br />I Do For<br />You?</h2>
            </div>
            <div className='grid-item'>
                <div className="_details">
                    <h3>Personalized Coaching</h3>
                    <ul>
                        <li>Tailored lessons for all skill levels</li>
                        <li>One-on-one sessions</li>
                        <li>Customized drills and exercises</li>
                    </ul>
                    <h3>Skill Development</h3>
                    <ul>
                        <li>Improve serving, footwork, and strokes</li>
                        <li>Comprehensive game techniques</li>
                    </ul>
                    <h3>Competitive Training</h3>
                    <ul>
                        <li>Experienced match strategies</li>
                        <li>Mental conditioning</li>
                        <li>Tournament preparation</li>
                    </ul>
                    <h3>Fitness and Conditioning</h3>
                    <ul>
                        <li>Tennis-specific fitness routines</li>
                        <li>Strength and endurance training</li>
                    </ul>
                    <h3>Junior Programs</h3>
                    <ul>
                        <li>Fun and engaging for young players</li>
                        <li>Age-appropriate drills and games</li>
                    </ul>
                    <h3>Adult Programs</h3>
                    <ul>
                        <li>Lessons for all levels</li>
                        <li>Social and competitive play options</li>
                    </ul>
                </div>
                
            </div>
        </div>
    </div>
  )
}
