import React from 'react'
import './policy.css'

export const PrivacyPolicy = () => {
    const email = process.env.REACT_APP_EMAIL;
    const whatsappNum = process.env.REACT_APP_Num;
  return (
    <div className='policy'>
        <h2>Privacy Policy</h2>
        <p>Welcome to Veyselace ("we", "our", "us"). We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our website and services.</p>
        <h3>Information We Collect</h3>
        <p>We may collect and process the following information about you:</p>
        <ul>
            <li>Personal Identification Information: Name, email address, phone number, and other contact details.</li>
            <li>Demographic Information: Age, gender, preferences, and interests.</li>
            <li>Transaction Information: Details of the services you have requested or purchased from us.</li>
            <li>Technical Information: IP address, browser type, and version, time zone setting, browser plug-in types and versions, operating system, and platform.</li>
            <li>Usage Data: Information about how you use our website, products, and services.</li>
            <li>Marketing and Communications Data: Your preferences in receiving marketing from us and your communication preferences.</li>
        </ul>
        <h3>How We Use Your Information</h3>
        <p>We use the information we collect for various purposes, including to:</p>
        <ul>
            <li>Provide and manage our services.</li>
            <li>Personalize your experience on our website.</li>
            <li>Process your transactions and manage your bookings.</li>
            <li>Communicate with you, including sending promotional emails and notifications.</li>
            <li>Improve our website, products, and services.</li>
            <li>Comply with legal obligations and protect our legal rights.</li>
        </ul>
        <h3>How We Share Your Information</h3>
        <p>We do not sell, trade, or otherwise transfer your personal information to outside parties except as described below:</p>
        <ul>
            <li>Service Providers: We may share your information with third-party service providers who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.</li>
            <li>Legal Requirements: We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
        </ul>
        <h3>Security of Your Information</h3>
        <p>We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, use, alteration, and disclosure. However, please note that no method of transmission over the internet or method of electronic storage is completely secure.</p>
        <h3>Your Rights</h3>
        <p>You have the following rights regarding your personal information:</p>
        <ul>
            <li>Access: You have the right to request access to the personal information we hold about you.</li>
            <li>Correction: You have the right to request correction of any inaccurate or incomplete information.</li>
            <li>Deletion: You have the right to request the deletion of your personal information.</li>
            <li>Restriction: You have the right to request the restriction of processing of your personal information.</li>
            <li>Objection: You have the right to object to the processing of your personal information.</li>
            <li>Data Portability: You have the right to request the transfer of your personal information to another party.</li>
        </ul>
        <h3>Cookies</h3>
        <p>Our website uses cookies to enhance your browsing experience. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your web browser that enables the site's or service provider's systems to recognize your browser and capture and remember certain information.</p>
        <h3>Changes to This Privacy Policy</h3>
        <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>
        <h3>Contact Us</h3>
        <p>If you have any questions about this Privacy Policy, please contact us at:</p>
        <ul>
            <li>Email: ${email}</li>
            <li>Phone: ${whatsappNum}</li>
        </ul>
    </div>
  )
}
