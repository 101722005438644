import React from 'react'
import MainVisual from './mv/MainVisual';
import CtaBtn from './cta/cta';
import Body02 from './body02/body02';
import About from './about/about';
import Body05 from './body05/body05';
import Location from './location/location';
import Steps from './steps/steps';
import Gallery from './gallery/gallery';
import Body03 from './body03/Body03';
import Contact from './contact/contact';
import { Whatcanido } from './whatcanido/Whatcanido';
import Stories from './story/Stories';
import ReactGA from "react-ga4";

const HomePage = () => {
  ReactGA.send({ hitType: "pageview", page: "/" });
    return (
        <div className="Top">
          <MainVisual />
          <About />
          <CtaBtn text="Ask Schedule?"/>
          <Whatcanido />
          <CtaBtn />
          <Stories />
          <Body02/>
          <Body03/>
          <CtaBtn text="Ask Price?"/>
          <Location/>
          <CtaBtn text="Ask Location?"/>
          <Body05/>
          <CtaBtn text="Ask Plan?"/>
          <Steps/>
          <Contact/>
          <Gallery/>
        </div>
    );
}

export default HomePage