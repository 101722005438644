import React from 'react'
import './policy.css'

export const Terms = () => {
    const email = process.env.REACT_APP_EMAIL;
    const whatsappNum = process.env.REACT_APP_Num;
  return (
    <div className='policy'>
        <h2>Terms of Service</h2>
        <p>Welcome to Veyselace.com ("we", "our", "us"). These Terms of Service ("Terms") govern your use of our website and services. By accessing or using our website, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you must not use our website.</p>
        <h3>1. Acceptance of Terms</h3>
        <p>By using our website, you confirm that you accept these Terms and agree to comply with them. If you do not agree to these Terms, you must not use our website.</p>
        <h3>2. Changes to Terms</h3>
        <p>We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on this page. Your continued use of the website following the posting of revised Terms means that you accept and agree to the changes.</p>
        <h3>3. Accessing Our Website</h3>
        <p>3.1 We do not guarantee that our website, or any content on it, will always be available or be uninterrupted. Access to our website is permitted on a temporary basis. We may suspend, withdraw, discontinue, or change all or any part of our website without notice.</p>
        <p>3.2 You are responsible for ensuring that all persons who access our website through your internet connection are aware of these Terms and other applicable terms and conditions, and that they comply with them.</p>
        <h3>4. Use of Services</h3>
        <p>4.1 You must be at least 18 years old to use our services. If you are under 18, you must have the permission of a parent or guardian to use our services.</p>
        <p>4.2 You agree to provide accurate, current, and complete information about yourself as prompted by the registration process and to update such information to keep it accurate, current, and complete.</p>
        <p>4.3 You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer or device. You agree to accept responsibility for all activities that occur under your account or password.</p>
        <h3>5. Bookings and Payments</h3>
        <p>5.1 All bookings for tennis lessons must be made through our website. You agree to provide accurate and complete information when making a booking.</p>
        <p>5.2 Payment for our services must be made at the time of each lesson. We accept the payment method only cash.</p>
        <p>5.3 We reserve the right to cancel any booking if payment is not received or if there is any issue with the payment method provided.</p>
        <h3>6. Cancellation and Refunds</h3>
        <p>6.1 If you need to cancel a booking, you must notify us at least 2 hours before the scheduled lesson. Cancellations made within this period will be eligible for rescheduling.</p>
        <p>6.2 We do not provide refunds for any lessons after they have been completed. All completed lessons are non-refundable.</p>
        
        <h3>7. User Conduct</h3>
        <p>7.1 You agree not to use our website for any unlawful purpose or in any way that might harm, damage, or disparage any other party.</p>
        <p>7.2 You must not use our website to transmit or upload any material that contains viruses or any other harmful programs.</p>
        <h3>8. Intellectual Property Rights</h3>
        <p>8.1 We are the owner or the licensee of all intellectual property rights in our website, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.</p>
        <p>8.2 You may print off one copy, and may download extracts, of any page(s) from our website for your personal use and you may draw the attention of others to content posted on our website.</p>
        <p>8.3 You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</p>
        <h3>9. Limitation of Liability</h3>
        <p>9.1 To the extent permitted by law, we exclude all conditions, warranties, representations, or other terms which may apply to our website or any content on it, whether express or implied.</p>
        <p>9.2 We will not be liable to any user for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:</p>
        <ul>
            <li>Use of, or inability to use, our website; or</li>
            <li>Use of or reliance on any content displayed on our website.</li>
        </ul>
        <h3>10. Governing Law</h3>
        <p>These Terms are governed by and construed in accordance with the laws of New South Wales, Australia. You agree that the courts of New South Wales will have exclusive jurisdiction over any dispute or claim arising out of or in connection with these Terms.</p>
        <h3>11. Contact Us</h3>
        <p>If you have any questions about these Terms, please contact us at:</p>
        <ul>
            <li>Email: ${email}</li>
            <li>Phone: ${whatsappNum}</li>
        </ul>
    </div>
  )
}
